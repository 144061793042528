.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
  position: fixed;
  top: 2.5rem;
  width: 100%;
}

.dndflow aside {
  padding: 15px 10px;
  font-size: 12px;
  background: #FFFFFF;
  box-shadow: 0px 3.200000047683716px 7.199999809265137px 0px #00000021;
  border: 1px solid #CBCBCB;
  opacity: 1;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndnodeSidebar {
  height: 2.2rem;
  padding: 0 0 0 0;
  border: 1px solid rgb(216, 213, 213);
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.sidebarText {
  border: none;
  font-size: 14px;
  margin-top: -.5rem;
  margin-bottom: 1rem;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 93%;
}

.dndflow .selectall {
  margin-top: 10px;
}

.mandatory {
  color: red;
  font-weight: bold;
  font-size: 14px;
}

.connectWindow {
  width: 23rem;
  padding: .5rem;
  top: 30%;
  left: 35%;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

div[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .header {
    height: 3%;
    width: 100%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .dndflow .property {
    max-width: 20%;
    min-width: 20%;
    padding: 15px;
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid #ECECEC;
    opacity: 1;
    overflow: scroll;
  }

  .text {
    margin-left: 5px;
    font-size: 12px;
  }

  .divide {
    margin-left: 15px;
    margin-right: 15px;
    color: rgb(216, 213, 213);
  }

  .but {
    padding: 0px 15px 0px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .small {
    cursor: pointer;
    margin-left: 10px;
    font-size: 1rem;
  }
}

div.example input[type=text] {
  border: 1px solid grey;
  float: left;
  width: 87%;
}

div.example button {
  float: left;
  width: 10%;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

.connectField {
  display: inline-block;
  margin-bottom: 10px;
}

.connectLabel {
  font-weight: 400;
  padding-left: 10px;
  width: 35%;
}

.connectInput {
  width: 200px;
}

.popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-content {
  background-color: white;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888888;
  min-width: 20%;
  max-width: 28%;
}


.btn {
  border: none;
  background-color: inherit;
  padding: 10px 14px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.info {
  color: dodgerblue;
}

.SelectNodeLabel {
margin-bottom: 10px;
  margin-top: 5px;
}