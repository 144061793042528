.navigation {
    height: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 100;
    background-color: rgb(25, 118, 210);
    color: white;
    box-shadow: 0 .5px 2px 2px rgba(9, 9, 9, 0.23);
  }

.brand-name {
    text-decoration: none;
    font-size: 1rem;
    margin-left: 1rem;
  }
.navigation-menu {
    margin-left: auto;
    margin-right:.5rem;
  }
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }

.navigation-menu li {
    list-style-type: none;
    margin: 0 .5rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .popupdiv {
    position: relative;
    display: inline-block;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .popupdiv .popuptext {
    width: 340px;
    background-color: white;
    box-shadow: 0 .5px 2px 2px rgba(9, 9, 9, 0.23);
    color: black;
    padding: 10px 10px;
    margin-top:8px;
    position: absolute;
    z-index: 1;
    margin-left: -320px;
  }