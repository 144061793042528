.label {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #323130;
    margin-top: 5px;
    margin-bottom: 3px;
}

.textbox {
    width: 100%;
    height: 32px;
    padding: 6px 8px 6px 8px;
    border-radius: 2px;
    border: 1px;
    border: 1px solid #605E5C;
    box-sizing: border-box;
}

.textbox::placeholder {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #323130;
}

/* ---------------- Radio button ------------------- */
.radio-container {
     /* Display options side by side */
    display: flex;
     /* Vertically center the options */
    align-items: center;
  
}

.radiotext {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #323130;
}

.radio {
    display: flex;
    align-items: center;
    width: 80px;
    border-radius: 10px;
    border: 1px;
    background-color: 1px solid red;
}

.radioinput{
    margin: '10px';
}

.radio span {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #323130;
}


/* ------------ Locale ------------------- */

.localeBox {
    width: 100%;
    height: 32px;
    padding: 6px 8px 6px 8px;
    border-radius: 2px;
    border: 1px;
    border: 1px solid #605E5C;
}

.localeHelperText {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    background: red;
}

/* --------------- Numeric -------------------- */
.numericbox {
    width: 100%;
    height: 32px;
    padding: 6px 8px 6px 8px;
    border-radius: 2px;
    border: 1px;
    border: 1px solid #605E5C;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.slider
{
    width: 90%;
    height: 5px;
    max-width: 400px;
    margin: 0;
    padding: 0;
    border:none;
    outline: none;
    border-radius: 20px;
    cursor: pointer;
}