*{
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}
body {
  margin: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label{
  font-size: 12px;
  width: 50%;
}

.loader-container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.sidebox{
  height: 100vh;
  top: 48px;
  left: 150px;
  border-radius: 2px;
  box-shadow: 0px 3.200000047683716px 7.199999809265137px 0px #00000021;
}

.nameStyle{
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.button{
  width: Hug (104px);
  height: Hug (32px);
  padding: 6px 20px 6px 20px;
  border-radius: 3px;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  Color: #FFFFFF;
}

.selectLable{
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #323130;
}
.selectStyle{
  width: 300px;
  height: 32px;
  padding: 6px 8px 6px 8px;
  border-radius: 2px;
  border: 1px;
  border: 1px solid #605E5C
}

.headerStyle{
font-family: Segoe UI;
font-size: 24px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0px;
text-align: left; 
color: #2A2A2A;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  cursor: pointer;
}
.dropdown-content div:hover {background-color: #ddd;}
.show {display: block;}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}