h1, p {
  font-family: Lato;
}

.slider-container {
box-sizing: border-box;
}

.slider-list{
  display: inline-flex;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-right: calc(100% - 400px); 
}

.slider-item {
  background-color: #C5CAE9;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
}   

.slider-item:not(:last-of-type) {
  margin-left: 10px;
  margin-right: 5px;
}

.notification {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
}

.notification.show {
  transform: translateX(0);
}


@media (max-width: 1000px) {
  .slider-container {
    padding-right: 80vh !important; /* dynamic width = 80vh */ 
  }
  .slider-item {
    width: 10vw; 
  }
}

  .MainDiv{
    background-color: white;
    border-radius: 0.5rem;
    padding: 2rem;

  }
 /* Example CSS (Add to your CSS file or use inline styles) */
.table-container {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.table-sort-label {
  cursor: pointer;
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.table-page {
  display: flex;
  align-items: center;
}

.table-page button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.table-page button[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}
.sort-arrow {
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}

.sort-arrow:hover {
  opacity: 1; /* Show the arrow on hover */
}

.table-header {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  
}

.table-header:hover .sort-arrow {
  opacity: 1;
}

tbody {
  font-family: Segoe UI;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.responsive-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  border-radius: 2px;
box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px #00000021;
box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001A;

}

.modal-content {
  width: 40%;
  max-width: 400px;
  max-height: 40%;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  color: black;
  margin-bottom: 23px;
  font-size: 16px;
  font-weight: 500;
}

.modal-close {
  min-width: 0;
  padding: 0;
  background-color: transparent;
  color: black;
  border: none;
  cursor: pointer;
  margin-top: -25px;
}

.create-button {
  background-color: #0066FF;
  height: 25px;
  margin-right: 14px;
  width: 80px;
  font-size: 10px;
  border-radius: 0;
  color: white;
  border: none;
}

.cancel-button {
  background-color: white;
  height: 25px;
  width: 80px;
  font-size: 10px;
  border-radius: 0;
  border: 1px solid black;
  color: black;
}




